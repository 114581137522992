<template>
  <div class="overflow-hidden z-30 reace">
    <div
      class="absolute z-30 top-5 left-10 ml-12 bg-white right-5 bottom-5 grid grid-cols-3 rounded-xl shadow"
      style="width: 800px; grid-template-rows: auto auto auto 1fr"
      v-if="isRaceShown"
    >
      <div
        class="bg-white mx-auto overflow-y-auto rounded-xl"
        style="width: 800px; height: 95vh"
      >
        <div
          class="cont h-28 bg-white w-full z-50 inset-0 pt-3 pl-16 pr-6 transition-all ease-out duration-500"
          style="width: 100%"
        >
          <div class="flex justify-between items-center pb-4">
            <div class="font-bold text-darkblue text-xl">
              {{ $t('routesTrips.addTrip') }}
            </div>
            <i
              class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
              @click="$emit('close'), cleanMap()"
            ></i>
          </div>
          <div class="flex justify-end items-center pb-4 pr-10">
            <skif-button
              class="mb-4 mr-2 float-right"
              variant="normal"
              @click="$emit('close'), cleanMap()"
              >{{ $t('btn.cancel') }}</skif-button
            >
            <skif-button
              class="mb-4 float-right"
              :disabled="isLabelDatePeriod"
              @click.prevent="submitForm"
              >{{ $t('btn.save') }}</skif-button
            >
          </div>
        </div>
        <form class="flex flex-col w-full mt-6 px-16">
          <div class="flex items-center w-full">
            <label for="inputName" class="label w-48">{{
              $t('routesTrips.trip.name')
            }}</label>
            <div class="flex-grow">
              <input
                id="inputName"
                class="input flex-grow outline-none w-full"
                type="text"
                :placeholder="$t('routesTrips.trip.name.placeholder')"
                v-model="$v.formToSubmit.raceName.$model"
                :class="{ emptyName: $v.formToSubmit.raceName.$error }"
              />
              <transition name="fade">
                <div v-if="$v.formToSubmit.raceName.$error">
                  <div
                    class="error text-xs"
                    v-if="!$v.formToSubmit.raceName.required"
                  >
                    {{ $t('routesTrips.trip.validation') }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="flex">
            <div class="flex w-48 items-center">
              <span
                class="text-annotationColor font-SourceSansPro text-sm font-semibold pt-4 label8 mb-4 flex items-center"
                >{{ $t('routesTrips.trip.object') }}</span
              >
              <div
                class="flex cursor-pointer ml-2 relative"
                v-if="isOnlyUnits"
                style="left: '120px'"
              >
                <svg
                  class="ml-4"
                  @click.stop="isShowAllUnits = !isShowAllUnits"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    :fill="!showAllUnits ? '#F56C6C' : '#5477A9'"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z"
                    :fill="!showAllUnits ? '#F56C6C' : '#5477A9'"
                  ></path>
                </svg>
                <transition name="slide-fade" mode="out-in">
                  <div
                    class="absolute bg-white px-2 py-4 pl-3"
                    v-if="isShowAllUnits"
                    style="
                      left: 20px;
                      border: 1px solid #cfdbeb;
                      bottom: 25px;
                      width: 320px;
                      border-radius: 5px;
                    "
                  >
                    <div
                      class="text-darkblue font-SourceSansPro text-sm font-semibold"
                    >
                      <div class="flex mb-2">
                        <svg
                          class="mt-2 mr-4"
                          width="20"
                          height="20"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                            fill="#5477A9"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z"
                            fill="#5477A9"
                          ></path>
                        </svg>
                        <div class="flex flex-col">
                          <span>Применена фильтрация из Мониторинга.</span
                          ><span>Отображаются не все объекты.</span>
                        </div>
                      </div>
                      <div class="flex items-center">
                        <skif-checkbox
                          class="mr-4 ml-1"
                          v-model="showAllUnits"
                          @change="toggleAllUnits"
                        ></skif-checkbox
                        ><span
                          class="text-darkblue font-SourceSansPro text-sm font-semibold"
                          >Показать все объекты</span
                        >
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <div class="flex-grow">
              <el-select
                class="mt-3 flex-grow element-input outline-none w-full"
                :class="{ emptyName: $v.formToSubmit.getObject.$error }"
                v-model="$v.formToSubmit.getObject.$model"
                filterable="filterable"
                :placeholder="$t('routesTrips.trip.object.placeholder')"
              >
                <el-option
                  class="text-ellipsis"
                  style="max-width: 433px"
                  v-for="(item, i) in currentUnits"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <transition name="fade">
                <div v-if="$v.formToSubmit.getObject.$error">
                  <div
                    class="error text-xs"
                    v-if="!$v.formToSubmit.getObject.required"
                  >
                    {{ $t('routesTrips.trip.validation') }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="flex items-center mb-2">
            <label
              for="selectTrip"
              class="flex items-center justify-start label w-48 h-full self-start"
              >{{ $t('routesTrips.trip.activationType')
              }}<el-tooltip
                id="selectTrip"
                class="item"
                popperClass="race-el-tooltip"
                :open-delay="700"
                effect="dark"
                :content="$t('routesTrips.trip.activationType.tooltip')"
                placement="bottom"
                :offset="0"
                :visible-arrow="false"
                ><el-col class="extra-info" :span="1"></el-col></el-tooltip
            ></label>
            <div class="flex flex-col h-full mt-3">
              <div class="flex">
                <skifRadioOld
                  value="time_of_first_point"
                  :label="$t('routesTrips.trip.firstPoint')"
                  name="typeActivation"
                  v-model="activation_type"
                ></skifRadioOld>
              </div>
              <div class="flex">
                <skifRadioOld
                  name="typeActivation"
                  value="entrance_to_first_point"
                  v-model="activation_type"
                  :label="$t('routesTrips.trip.entranceToFirstPoint')"
                ></skifRadioOld>
              </div>
              <div class="flex">
                <skifRadioOld
                  :label="$t('routesTrips.trip.leavingToFirstPoint')"
                  name="typeActivation"
                  value="leaving_first_point"
                  v-model="activation_type"
                ></skifRadioOld>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-2">
            <label
              for="radioSkif"
              class="flex items-center justify-start label w-48 h-full self-start pt-1"
              >{{ $t('routesTrips.trip.order') }}</label
            >
            <div class="flex flex-col h-full mt-3">
              <div class="flex">
                <skifRadioOld
                  id="radioSkif"
                  :label="$t('routesTrips.trip.order.strict')"
                  name="order_type"
                  value="strict"
                  v-model="passage_order_type"
                ></skifRadioOld>
              </div>
              <div class="flex">
                <skifRadioOld
                  :label="$t('routesTrips.trip.order.arbitrary')"
                  name="order_type"
                  value="arbitrary"
                  v-model="passage_order_type"
                ></skifRadioOld>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-3">
            <label
              for="datePicker"
              class="flex items-center justify-start label h-full self-start"
              style="display: inline-block; width: 271px"
            >
              {{ $t('routesTrips.trip.period') }}
            </label>
            <div class="flex w-full flex-col">
              <el-date-picker
                id="picker"
                style="justify-self: end; width: 100%"
                :class="{ emptyName: $v.formToSubmit.date.$error }"
                v-model.lazy="$v.formToSubmit.date.$model"
                type="daterange"
                :placeholder="$t('routesTrips.trip.date.placeholder')"
                range-separator="-"
                :format="dateFormat"
                value-format="yyyy-MM-dd"
                :start-placeholder="$t('reports.not-select')"
                :end-placeholder="$t('reports.not-select')"
                :clearable="false"
                @change="showDates"
              />
              <transition name="fade">
                <div v-if="$v.formToSubmit.date.$error">
                  <div
                    class="error text-xs"
                    v-if="!$v.formToSubmit.date.required"
                  >
                    {{ $t('routesTrips.trip.validation') }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="flex items-center">
            <label
              class="flex items-center justify-start label h-full self-start"
              for="datePickerPeriod"
              style="display: inline-block; width: 271px"
            >
              {{ $t('routesTrips.trip.date.total_days_period') }}
            </label>
            <div class="w-full relative">
              <el-date-picker
                style="justify-self: start; width: 100%"
                :class="{ emptyName: $v.formToSubmit.dateRange.$error }"
                v-model.lazy="$v.formToSubmit.dateRange.$model"
                type="dates"
                :placeholder="$t('routesTrips.trip.date.placeholder')"
                range-separator="-"
                :format="dateFormat"
                value-format="yyyy-MM-dd"
                :clearable="false"
              />
              <transition name="fade">
                <div
                  v-if="isLabelDatePeriod || $v.formToSubmit.date.$error"
                  class="flex text-xs items-center justify-start w-56 h-full self-start"
                  style="
                    display: inline-block;
                    width: 100%;
                    color: rgb(255, 108, 108);
                  "
                >
                  {{ $t('routesTrips.trip.validation.total_period') }}
                </div>
              </transition>
              <div class="days-periods">
                <p class="days-periods__days-period" @click="showDaysPeriod">
                  {{ $t('routesTrips.trip.date.set_days_period') }}
                </p>
                <p class="days-periods__days-period" @click="resetDaysPeriod">
                  {{ $t('routesTrips.trip.date.reset_days_period') }}
                </p>
              </div>
              <DaysPeriod
                v-if="isDaysPeriod"
                @toggle-days="toggleWeekDays"
                @accept-filter-period="acceptFilterPeriod"
                @close-days-period="isDaysPeriod = false"
              />
            </div>
          </div>
          <div class="flex mb-3">
            <span
              class="text-annotationColor font-SourceSansPro text-sm font-semibold pt-4 label w-48 mb-4"
              >{{ $t('routesTrips.trip.driver') }}</span
            >
            <el-select
              class="mt-3 flex-grow element-input"
              v-model="getDriver"
              filterable="filterable"
              :placeholder="$t('routesTrips.trip.driver')"
            >
              <el-option
                v-for="(item, i) in users"
                :key="i"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="flex">
            <label class="pt-2 label w-48" for="multiselect">{{
              $t('routesTrips.trip.workType')
            }}</label>
            <div class="flex flex-col flex-grow">
              <skifMultiSelect
                id="multiselect"
                v-model="multipleSelection"
                :options="multiSelectOptions"
                :placeholder="$t('routesTrips.trip.workType')"
                :tag-placeholder="$t('add')"
                width="436"
                @tag="addMe"
              />
            </div>
          </div>
          <div class="flex mb-3">
            <span
              class="text-annotationColor font-SourceSansPro text-sm font-semibold pt-4 label w-48 mb-4"
              >{{ $t('routesTrips.trip.trailer') }}</span
            >
            <el-select
              class="mt-3 flex-grow element-input"
              v-model="getTrailer"
              filterable="filterable"
              :placeholder="$t('routesTrips.trip.trailer')"
            >
              <el-option
                v-for="(item, i) in trailers"
                :key="i"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="flex items-center mt-4 w-full justify-between">
            <button
              type="button"
              class="transparentBtn mr-4"
              @click="
                ;(isAddFromRoutes = true),
                  getFullRoutes(),
                  (isRaceShown = false)
              "
            >
              {{ $t('routesTrips.trip.addFromRoutes') }}
            </button>
            <button
              type="button"
              class="transparentBtn mr-4"
              @click="
                ;(isGeozoneCreating = true),
                  (isRaceShown = false),
                  $store.commit('geozones/START_DRAWING')
              "
            >
              {{ $t('routesTrips.trip.addFromMap') }}
            </button>
            <button
              type="button"
              class="transparentBtn"
              @click=";(isAddFromGeozones = true), (isRaceShown = false)"
            >
              {{ $t('routesTrips.trip.addFromGeozones') }}
            </button>
          </div>
          <transition name="fade">
            <div
              class="w-full flex justify-center mt-8 border border-red-200 p-5"
              v-if="$v.points.$invalid"
            >
              <div class="error">
                {{ $t('routesTrips.trip.pointsValidation') }}
              </div>
            </div>
          </transition>
        </form>
        <div class="px-4 mt-4">
          <table class="w-full table" v-if="points.length &gt; 0">
            <thead style="border-bottom: 1px solid #eef5ff">
              <th class="w-6"></th>
              <th class="w-6">
                <skif-checkbox-old
                  class="p-2"
                  @click.native="(event) =&gt; event.stopPropagation()"
                  style="margin-left: -8px"
                  v-model="isAllGeozonesChecked"
                  :indeterminate="isIndeterminate"
                />
              </th>
              <th style="width: 200px">{{ $t('routesTrips.trip.point') }}</th>
              <th class="pl-4" style="width: 120px">
                {{ $t('routesTrips.trip.arrival') }}
              </th>
              <th class="pl-4" style="width: 130px">
                {{ $t('routesTrips.trip.arrivalDev') }}
              </th>
              <th class="pl-4" style="width: 140px">
                {{ $t('routesTrips.trip.departure') }}
              </th>
              <th class="pl-0">{{ $t('routesTrips.trip.departureDev') }}</th>
              <th class="pl-2" style="width: 25px; height: 40px">
                <div class="flex items-center justify-center">
                  <transition name="fade">
                    <div
                      class="flex items-center justify-start cursor-pointer"
                      v-if="pointSelected.length &lt;= 0"
                      @click="clearTable"
                    >
                      <el-tooltip
                        class="item"
                        :open-delay="700"
                        effect="light"
                        :content="$t('routesTrips.trip.clearDays')"
                        placement="bottom"
                        :offset="0"
                        :visible-arrow="false"
                      >
                        <img
                          src="../../assets/icons/clear.svg"
                          style="width: 18px; margin-right: 10px"
                          alt=""
                        />
                      </el-tooltip>
                    </div>
                  </transition>
                  <transition name="fade">
                    <span
                      class="h-9 w-9 text-darkblue leading-10 cursor-pointer group relative"
                      v-if="pointSelected.length &gt; 0"
                      style="
                        font-size: 14px;
                        color: #91b4e7;
                        margin-right: 10px;
                      "
                      @click="deletePoints()"
                    >
                      <transition name="fade">
                        <button
                          class="h-9 w-9 group relative link"
                          type="button"
                          style="margin-top: 0px"
                        >
                          <svg
                            class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#91B4E7"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                            />
                          </svg>
                          <skif-badge
                            list="list"
                            :value="pointSelected.length"
                          />
                        </button>
                      </transition>
                    </span>
                  </transition>
                </div>
              </th>
            </thead>
            <draggable v-model="$v.points.$model" tag="tbody">
              <tr
                class="h-10"
                v-for="(point, index) in $v.points.$model"
                :key="index"
                :class="[isChecked ? 'itemSelected' : '']"
              >
                <td class="mr-2">{{ index + 1 }}</td>
                <td>
                  <skif-checkbox-old
                    type="checkbox"
                    v-model="pointSelected"
                    :label="point.id"
                  />
                </td>
                <td>
                  <div class="flex">
                    <label for="inputPointName"></label>
                    <input
                      id="inputPointName"
                      class="outline-none border mr-2 h-8 px-4 tableInput"
                      style="width: 138px"
                      type="text"
                      v-model="point.name"
                    />
                    <img
                      alt=""
                      @click="
                        cleanMap(point),
                          editGeo(point),
                          (isEditGeozone = true),
                          (isRaceShown = false)
                      "
                      src="../../assets/icons/edit-flight.svg"
                      style="width: 18px"
                    />
                    <div class="flex ml-1 plusMinus">
                      <div class="flex mr-1 cursor-pointer">
                        <el-tooltip
                          class="item"
                          :open-delay="700"
                          effect="light"
                          :content="$t('routesTrips.trip.addDays')"
                          placement="bottom"
                          :offset="0"
                          :visible-arrow="false"
                        >
                          <p @click="arrivalAddDay(index)">+</p>
                        </el-tooltip>
                      </div>
                      <div class="flex mr-1 cursor-pointer">
                        <el-tooltip
                          class="item"
                          :open-delay="700"
                          effect="light"
                          :content="$t('routesTrips.trip.removeDays')"
                          placement="bottom"
                          :offset="0"
                          :visible-arrow="false"
                        >
                          <p @click="arrivalRemoveDay(index)">-</p>
                        </el-tooltip>
                      </div>
                    </div>
                    <p class="plusMinus">1</p>
                  </div>
                </td>
                <td class="pl-4 flex w-36 items-center h-14">
                  <el-time-picker
                    class="tableInputTime normalInput"
                    v-model="point.arrival.time"
                    :class="!point.arrivalArrival ? 'empty' : ''"
                    value-format="HH:mm"
                    format="HH:mm"
                    popper-class="tableInputTime"
                    size="mini"
                    placeholder="00:00"
                    prefix-icon="el-icon-time"
                  />
                  <p class="timePlus ml-2">{{ point.arrivalDaysAdd }}</p>
                </td>
                <td class="pl-3">
                  <div class="flex">
                    <el-time-picker
                      class="tableInputTime"
                      v-model="point.arrival.deviation_minutes"
                      value-format="HH:mm"
                      format="HH:mm"
                      popper-class="tableInputTime"
                      size="mini"
                      placeholder="00:00"
                      type="datetime"
                      prefix-icon="el-icon-time"
                      :picker-options="{
                        start: '00:00',
                        step: '00:05',
                        end: '24:00'
                      }"
                    />
                    <div
                      class="flex ml-3 relative"
                      style="
                        width: 40px;
                        justify-content: center;
                        align-items: center;
                        height: 22px;
                      "
                    >
                      <div class="flex ml-3 plusMinus">
                        <div class="flex mr-1 cursor-pointer">
                          <el-tooltip
                            class="item"
                            :open-delay="700"
                            effect="light"
                            :content="$t('routesTrips.trip.addDays')"
                            placement="bottom"
                            :offset="0"
                            :visible-arrow="false"
                          >
                            <p @click="departureAddDay(index)">+</p>
                          </el-tooltip>
                        </div>
                        <div class="flex mr-1 cursor-pointer">
                          <el-tooltip
                            class="item"
                            :open-delay="700"
                            effect="light"
                            :content="$t('routesTrips.trip.removeDays')"
                            placement="bottom"
                            :offset="0"
                            :visible-arrow="false"
                          >
                            <p @click="departureRemoveDay(index)">-</p>
                          </el-tooltip>
                        </div>
                      </div>
                      <p class="plusMinus mr-3">1</p>
                    </div>
                  </div>
                </td>
                <td class="flex items-center ml-6">
                  <el-time-picker
                    class="tableInputTime normalInput"
                    v-model="point.departure.time"
                    value-format="HH:mm"
                    format="HH:mm"
                    popper-class="tableInputTime"
                    size="mini"
                    placeholder="00:00"
                    type="datetime"
                    prefix-icon="el-icon-time"
                    :picker-options="{
                      start: '00:00',
                      step: '00:05',
                      end: '24:00'
                    }"
                  ></el-time-picker>
                  <p class="timePlus ml-3 cursor-pointer">
                    {{ point.departureDaysAdd }}
                  </p>
                </td>
                <td class="ml-1">
                  <el-time-picker
                    class="tableInputTime"
                    v-model="point.departure.deviation_minutes"
                    value-format="HH:mm"
                    format="HH:mm"
                    popper-class="tableInputTime"
                    size="mini"
                    placeholder="00:00"
                    type="datetime"
                    prefix-icon="el-icon-time"
                    :picker-options="{
                      start: '00:00',
                      step: '00:05',
                      end: '24:00'
                    }"
                  ></el-time-picker>
                </td>
                <td class="pl-2">
                  <img src="../../assets/icons/move.svg" alt="" />
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
    <transition name="dialog-fade">
      <addFromGeozones
        v-if="isAddFromGeozones"
        @close=";(isAddFromGeozones = false), (isRaceShown = true)"
        @my-done="fromGeozones"
      ></addFromGeozones>
    </transition>
    <transition name="dialog-fade">
      <createGeozones
        v-if="isGeozoneCreating"
        @close=";(isGeozoneCreating = false), (isRaceShown = true)"
        @my-done="createdGeozone"
        @createGeozone="
          isCreatingGeo = true
          isGeo = false
        "
      ></createGeozones>
    </transition>
    <transition name="dialog-fade">
      <addFromRoutes
        v-if="isAddFromRoutes"
        @close=";(isAddFromRoutes = false), (isRaceShown = true)"
        @my-done="fromRoutes"
        @createGeozone="
          isCreatingGeo = true
          isGeo = false
        "
      ></addFromRoutes>
    </transition>
    <transition name="dialog-fade">
      <editGeozone
        v-if="isEditGeozone"
        @close=";(isEditGeozone = false), (isRaceShown = true)"
        @createGeozone="
          isCreatingGeo = true
          isGeo = false
        "
        @edited-geo="handleEditedGeo"
      ></editGeozone>
    </transition>
  </div>
</template>

<script>
import skifMultiSelect from '@/utils/skifMultiSelect'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { routesApi, racesApi } from '@/api'
import eventBus from '@/eventBus.js'
import moment from 'moment'

// import {required, minLength,maxLength} from 'vuelidate/lib/validators'

export default {
  components: {
    draggable,
    skifMultiSelect,
    addFromGeozones: () => import('@/components/race/addFromGeozones'),
    createGeozones: () => import('@/components/race/createGeozones'),
    addFromRoutes: () => import('@/components/race/addFromRoutes'),
    editGeozone: () => import('@/components/race/editGeozone'),
    DaysPeriod: () => import('./components/DaysPeriod.vue')
  },

  data() {
    return {
      showAllUnits: false,
      isOnlyUnits: false,
      isShowAllUnits: false,
      customUnits: [],
      isEditGeozone: false,
      errors: false,
      empty: true,
      formToSubmit: {
        raceName: '',
        getObject: '',
        date: '',
        dateRange: []
      },
      isAddFromRoutes: false,
      isNameInvalid: false,
      isObjectInvalid: false,
      isChecked: false,
      pointSelected: [],
      isIndeterminate: false,
      isGeozoneCreating: false,
      isRaceShown: true,
      isAddFromGeozones: false,

      activation_type: 'time_of_first_point',
      passage_order_type: 'strict',
      lucky: '',
      getTrailer: '',
      getDriver: '',
      selectedDays: '',
      daySelects: [
        {
          name: 'Каждый день',
          value: 'everyDay'
        },
        {
          name: 'Каждую неделю',
          value: 'everyWeek'
        },
        {
          name: 'Каждый месяц',
          value: 'everyMonth'
        },
        {
          name: 'По чётным дням',
          value: 4
        },
        {
          name: 'По2 чётным дням',
          value: 5
        },
        {
          name: 'Определённые дни',
          value: 6
        },
        {
          name: 'Через день',
          value: 7
        }
      ],
      options: [
        {
          name: 'Type work 1',
          label: 'Type Work 1'
        }
      ],
      scrolled: false,
      multipleSelection: [],
      multiSelectOptions: [
        { name: this.$t('routesTrips.trip.work_type_one') },
        { name: this.$t('routesTrips.trip.work_type_two') }
      ],
      points: [],
      editedGeo: null,
      pointsToClean: [],
      // date time format
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      isDaysPeriod: false,
      weekDaysPeriod: [],
      isLabelDatePeriod: false
    }
  },
  validations: {
    formToSubmit: {
      raceName: {
        required
      },
      getObject: {
        required
      },
      date: {
        required
      },
      dateRange: {
        required
      }
    },
    points: {
      required
      // $each: {
      //   name: {
      //     required,

      //   },

      // },
    }
  },
  computed: {
    ...mapState('units', {
      units: (state) => state.defaultUnits,
      unitGroups: (state) => state.unitsGroups
    }),
    ...mapState('users', {
      users: (state) => {
        return state.users.filter((user) => user.is_driver)
      }
    }),
    ...mapState('trailers', {
      trailers: (state) => state.trailers
    }),
    ...mapState('races', {
      geozones: (state) => state.geozones,
      existingGeozones: (state) => state.existingGeozones,
      routesPoints: (state) => state.routesPoints
    }),
    ...mapState('login', {
      me: (state) => state.me
    }),

    isAllGeozonesChecked: {
      get() {
        return this.points
          ? this.pointSelected.length === this.points.length
          : false
      },
      set(value) {
        const selected = []

        if (value) {
          this.points.map(function (point, index) {
            selected.push(point.id)
          })
        }
        this.pointSelected = selected
      }
    }
  },
  watch: {
    'formToSubmit.dateRange': {
      handler(newVal) {
        if (!newVal.length) {
          this.isLabelDatePeriod = true
        } else {
          this.isLabelDatePeriod = false
        }
      },
      deep: true
    }
  },

  methods: {
    acceptFilterPeriod(array) {
      this.isDaysPeriod = false
      array.forEach((el) => {
        this.toggleWeekDays(el)
      })
    },
    resetDaysPeriod() {
      this.formToSubmit.dateRange = this.showDates()
    },
    showDaysPeriod() {
      this.isDaysPeriod = !this.isDaysPeriod
    },
    // получим массив с днями недели и дату
    getDaysWeek(dates) {
      const dateObjects = dates.map((dateString) => {
        const date = new Date(dateString)
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' })
        const isEven = date.getDate() % 2 === 0

        return {
          date: dateString,
          weekDay: dayOfWeek,
          even: isEven
        }
      })
      return dateObjects || []
    },
    toggleWeekDays(day) {
      if (day.key === 'all-days') {
        this.formToSubmit.dateRange = this.weekDaysPeriod
      }
      if (day.key === 'even' || day.key === 'odd') {
        if (day.key === 'even') {
          const evenDays = this.getDaysWeek(this.formToSubmit.dateRange).filter(
            (el) => el.even
          )
          this.formToSubmit.dateRange = evenDays.map((el) => el.date)
        } else {
          const evenDays = this.getDaysWeek(this.formToSubmit.dateRange).filter(
            (el) => !el.even
          )
          this.formToSubmit.dateRange = evenDays.map((el) => el.date)
        }
      } else {
        const existingDay = this.getDaysWeek(
          this.formToSubmit.dateRange
        ).filter((el) => el.weekDay === day.key)
        if (existingDay.length) {
          const datesToRemove = existingDay.map((dateObj) => dateObj.date)
          const filteredDates = this.formToSubmit.dateRange.filter(
            (date) => !datesToRemove.includes(date)
          )
          const newDates = filteredDates
          this.formToSubmit.dateRange = newDates
        }
      }
    },
    closeDaysPeriod() {
      this.formToSubmit.dateRange = this.weekDaysPeriod
      this.isDaysPeriod = false
    },
    toggleAllUnits() {
      if (this.showAllUnits) {
        this.currentUnits = this.units
      } else if (this.customUnits.length) {
        this.currentUnits = this.customUnits
      } else {
        this.currentUnits = this.units
      }
      this.showAllUnits
        ? (this.currentUnits = this.units)
        : this.customUnits.length
        ? (this.currentUnits = this.customUnits)
        : (this.currentUnits = this.units)
    },
    showDates() {
      const date = []
      let start = this.formToSubmit.date[0]
      const end = this.formToSubmit.date[1]
      while (moment(start) <= moment(end)) {
        date.push(start)
        start = moment(start).add(1, 'days').format('YYYY-MM-DD')
      }
      this.formToSubmit.dateRange = date
      return date
    },
    handleEditedGeo(value) {
      this.editedGeo = value
      const oldGeoIndex = this.points.findIndex((x) => x.id === value.id)
      this.points[oldGeoIndex].name = value.name
      this.points[oldGeoIndex].geometry = value.geometry
      this.points[oldGeoIndex].width = value.width
    },
    editGeo(geo) {
      this.cleanMap(geo)
      this.$store.dispatch('races/END_EDITING', true)
      this.$store.dispatch('races/END_EDITING', false)
      this.$store.commit('routes/edit_geozone', geo)
    },
    isPointChecked() {
      this.isChecked = !this.isChecked
    },
    GenerateUniqueID() {
      return (Math.random() * (78500000 - 78400101) + 78400101) | 0
    },
    createdGeozone() {
      this.drawGeozone(this.geozones[0])
      this.points.push(
        ...this.geozones.map((item) => {
          return {
            ...item,
            departureDaysAdd: 0,
            arrivalDaysAdd: 0,
            arrival: {
              after_days: 0,
              deviation_minutes: '00:00',
              time: '00:00'
            },
            departure: {
              after_days: 0,
              deviation_minutes: '00:00',
              time: '00:00'
            }
          }
        })
      )
    },
    fromGeozones() {
      this.points.push(
        ...this.existingGeozones.map((item) => {
          return {
            ...item,
            id: this.GenerateUniqueID(),
            departureDaysAdd: 0,
            arrivalDaysAdd: 0,
            arrival: {
              after_days: 0,
              deviation_minutes: '00:00',
              time: '00:00'
            },
            departure: {
              after_days: 0,
              deviation_minutes: '00:00',
              time: '00:00'
            }
          }
        })
      )
    },
    addFromGeozones() {
      this.isRaceShown = false
    },
    convertToMinutes(time) {
      let minute
      if (time === undefined) {
        return
      }
      const a = time.split(':')
      minute = a[0] * 60 + +a[1]

      return minute
    },
    drawGeozone(geo) {
      const component = this

      component.$store.commit('routes/draw_geo', geo)
      component.pointsToClean.push(geo)
    },
    cleanMap() {
      this.$store.commit('routes/removeLastPoint', this.pointsToClean)
    },
    fromRoutes() {
      // console.log(this.routesPoints, 'wo')
      const component = this
      const pointsFromRoutes = this.routesPoints
        .map((route) => {
          return route.points.map((item) => {
            return { ...item, id: component.GenerateUniqueID() }
          })
        })
        .flat(Infinity)

      this.points.push(
        ...pointsFromRoutes.map((item) => {
          return {
            ...item,
            departureDaysAdd: 0,
            arrivalDaysAdd: 0,
            arrival: {
              after_days: 0,
              deviation_minutes: '00:00',
              time: '00:00'
            },
            departure: {
              after_days: 0,
              deviation_minutes: '00:00',
              time: '00:00'
            }
          }
        })
      )
    },
    submitForm() {
      // console.log('points check latlng', this.points)
      const component = this
      const points = this.points.map((point) => {
        // console.log('points data', point.width)

        return {
          name: point.name,
          type: point.type,
          geometry: point.geometry,
          arrival: {
            time:
              point.arrival.time === undefined ? '00:00' : point.arrival.time,
            // time: point.arrival.time === undefined ? '00:00' : point.arrivalArrival,
            after_days: point.arrivalDaysAdd,
            deviation_minutes:
              point === undefined
                ? '00:00'
                : this.convertToMinutes(point.arrival.deviation_minutes)
          },
          departure: {
            time:
              point.departure.time === undefined
                ? '00:00'
                : point.departure.time,
            after_days: point.departureDaysAdd,
            deviation_minutes:
              point === undefined
                ? '00:00'
                : this.convertToMinutes(point.departure.deviation_minutes)
          },
          width: point.width
        }
      })
      const multiselect = []
      this.multipleSelection.forEach((item) => multiselect.push(item.name))
      const data = {
        name: this.formToSubmit.raceName,
        unit: { id: this.formToSubmit.getObject },
        trailer: {
          id: this.getTrailer.length === 0 ? '' : this.getTrailer
        },
        user_id: this.getDriver,
        activation_type: {
          key: this.activation_type
        },
        passage_order_type: {
          key: this.passage_order_type
        },
        dates: this.formToSubmit.dateRange,
        work_types: multiselect,
        points
      }
      this.empty = !this.$v.formToSubmit.$anyDirty

      this.errors = this.$v.formToSubmit.$invalid
      this.$v.$touch()
      if (
        this.$v.formToSubmit.$anyError === false &&
        this.$v.points.$anyError === false
      ) {
        racesApi.createRace(
          data,
          (response) => {
            // console.log('sucesss',response)
            this.$store.dispatch('races/CREATE_RACE', response.data)
            this.cleanMap()
            component.$emit('close')
          },
          (error) => {
            // console.log('***error',error.response.data.message)
            eventBus.$showError(error.response.data.message)
          }
        )

        // this.$store.dispatch('races/CREATE_RACE', data).then((sucess, error) => {
        // component.$emit('close')
        // this.cleanMap()
        // })
      }
    },
    getFullRoutes() {
      const component = this
      routesApi.getAllRoutes(function (response) {
        component.$store.dispatch('routes/FULL_ROUTES', response.data)
      })
    },
    departureAddDay(e) {
      this.points.slice(e).forEach((item) => item.departureDaysAdd++)
      this.points.slice(e + 1).forEach((item) => {
        item.arrivalDaysAdd++
      })
    },
    departureRemoveDay(e) {
      this.points
        .slice(e)
        .forEach((item) =>
          item.departureDaysAdd > 0 ? item.departureDaysAdd-- : 0
        )
      this.points.slice(e + 1).forEach((item) => {
        item.arrivalDaysAdd > 0
          ? item.arrivalDaysAdd--
          : (item.arrivalDaysAdd = 0)
      })
    },
    arrivalAddDay(e) {
      this.points.slice(e).forEach((item) => {
        item.arrivalDaysAdd++
        item.departureDaysAdd++
      })
    },
    arrivalRemoveDay(e) {
      this.points.slice(e).forEach((item) => {
        item.arrivalDaysAdd > 0
          ? item.arrivalDaysAdd--
          : (item.arrivalDaysAdd = 0)
        item.departureDaysAdd > 0
          ? item.departureDaysAdd--
          : (item.departureDaysAdd = 0)
      })
    },
    deletePoints() {
      // console.log('points selected', this.pointSelected)
      this.pointSelected.forEach((item) => {
        this.points.splice(
          this.points.findIndex(function (i) {
            return i.id === item
          }),
          1
        )
      })
      this.pointSelected = []
    },
    clearTable() {
      this.points.map((item) => {
        item.departureDaysAdd = 0
        item.arrivalDaysAdd = 0
      })
    },

    addMe(va) {
      this.multiSelectOptions.push(va)
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleScroll(a) {
      this.scrolled = a.target.scrollTop > 0
    }
  },

  created() {
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
    const filters = JSON.parse(
      localStorage.getItem(`filtredUnits_${this.me.active_company.id}`)
    )
    if (filters && filters.units && filters.units.length) {
      this.customUnits = filters.units
      if (filters.units.length === this.units.length) {
        this.currentUnits = this.units
        this.isOnlyUnits = false
      } else {
        this.isOnlyUnits = true
        this.currentUnits = filters.units
      }
    } else {
      this.isOnlyUnits = false
      this.currentUnits = this.units
    }
  }
}
</script>
<style lang="scss" scoped>
.error {
  color: rgb(255, 108, 108);
  margin-left: 7px;
}

.days-periods {
  display: flex;
  &__days-period {
    color: #446c9e;
    font-size: 12px;
    margin-top: 8px;
    margin-right: 20px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    &:nth-of-type(1) {
      color: #ea7644;
    }
  }
}
</style>
<style>
.emptyName,
.emptyObject input,
.emptyDate {
  border: 1px solid rgb(255, 108, 108) !important;
  transition: 0.3s;
}

.plusMinus {
  font-size: 18px;
  font-weight: 800;
  color: #5477a9;
}
.itemSelected {
  font-weight: bold;
  font-size: 16px;

  color: #3c5e8f;
  background-color: #eef5ff;
  transition: all 0.3s ease-in-out;
}
.table .tableInputTime .el-input__inner::placeholder {
  color: #0d87f1;
}
.el-date-editor .el-input__inner::placeholder {
  color: #9a9fa3;
}
.table .el-date-editor.el-input {
  width: 80px;
}
.isObjectInvalid div input {
  border-color: #f56c6c;
}
.table {
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0 0.5em;
}
.table th {
  color: #91b4e7;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
}

.table thead {
  border-bottom: 1px solid #d9e2eb;
}

.tableInputTime .el-input__inner {
  border-radius: 4px;
  width: 80px;
  padding: 12px;
  height: 24px;
}
.tableInputTime .el-input__icon {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}
.tableInputTime .el-icon-time::before {
  position: absolute;
  content: '±';
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #677785;
}
.normalInput .el-icon-time::before {
  content: '';
}
.normalInput .el-icon-circle-close {
  display: none;
}
.tableInputTime .el-input__inner {
  padding-left: 20px;
}

.transparentBtn {
  color: #5477a9;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 2px;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
  font-family: Source Sans Pro;
  font-style: normal;
  line-height: 16px;
}
.transparentBtn:focus {
  outline: none;
}
.transparentBtn:hover {
  color: #3c5e8f;
}
.el-input__prefix {
  height: 0px;
}

.gridos {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
}
.option {
  color: #333333b6;
}
.selectos {
  display: flex;
}
.extra-info {
  margin-bottom: 10px;
  margin-left: 15px;
}
.extra-info:before {
  height: 18px;
  border: 1px solid #2f80ed;
  width: 18px;
  border-radius: 50%;
  content: '?';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 12px;
  color: #2f80ed;
  padding: 6px 6px 8px 6px;
}
.label {
  color: #91b4e7;
  font-size: 14px;
  font-weight: 600;
}
.input {
  background-color: #fefefe;
  border-radius: 4px;
  height: 35px;
  padding: 15px;
  font-weight: bold;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  color: #677785;
  font-size: 13px;
  border: 1px solid #d1dbe4;
}
.label-radio {
  margin-right: 0px;
  width: 228px;
  min-width: 150px;
  display: flex;
  align-items: center;
}
.tableInput {
  height: 30px;
  border: 1px solid #cfdbeb;
  border-radius: 4px;
  padding-bottom: 3px;
  color: #677785;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
}
.tableInput::placeholder,
.element-input input::placeholder,
.input::placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #677785;
  mix-blend-mode: normal;
}

.timePlus {
  color: white;
  background-color: #91b4e7;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
}
.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
button {
  outline: none;
}
.el-range-editor .el-icon-date {
  display: none;
}
.race-el-tooltip {
  width: 800px;
}
</style>
